<template>
  <div class="center">
    <img
      :src="
        !suc
          ? 'https://ppyos.xijiuyou.com/202202/bill-fail-icon.jpg'
          : 'https://ppyos.xijiuyou.com/202202/bill-success-icon.jpg'
      "
      alt=""
    />
    <p>{{ suc ? "支付成功" : "支付失败" }}</p>
    <p>
      {{ suc ? "最迟2个小时内到账，请耐心等待" : "支付失败，可返回重新支付" }}
    </p>
    <div @click="golong">返回</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      suc: true,
    };
  },
  methods:{
      golong(){
          this.$router.go(-1);
      }
  }
};
</script>
<style lang="less" scoped>
.center {
  text-align: center;
  padding-top: 26px;
  img {
    width: 126px;
  }
  p:nth-child(2) {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 10px 0;
  }
  p:nth-child(3) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-bottom: 83px;
  }
  div {
    width: 240px;
    height: 48px;
    background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>